<template>
  <div>
    <customer-details>
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
      </template>
      <template v-slot="{ id }">
        <div class="mt-3">
          <v-btn class="t-btn--red-dark btn-custom" @click="dele(id)" :disabled="!checkPerUser">
            <span>顧客情報を削除</span>
          </v-btn>
        </div>
      </template>
    </customer-details>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import CustomerDetails from './CustomerDetails.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';

export default {
  components: { CustomerDetails },
  name: 'Delete',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      idDel: null,
      visible: -1,
    };
  },
  computed: {

  },

  mounted() {},
  methods: {
    ...mapActions([
      'deleteClient',
    ]),
    ...mapMutations(['setAlertSuccess']),
    dele(clientId) {
      this.$confirm({
        title: '顧客情報削除',
        message: `顧客ID「${clientId}」の顧客情報削除を実行します。よろしいですか？（実行後やり直しはできません）`,
        ok: async () => {
          await this.deleteClient({
            clientId
          });
          this.$router.push({ path: '/customer' });
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .btn-custom {
    height: 32px !important;
    font-size: 14px !important;
    width: 130px !important;
  }
}
</style>
